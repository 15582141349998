import backofficeService from '../../api/backoffice'
import crmapi from '../../api/crmapi'


export default {

  /**
   * Get list of workOrders
   * @param {*} params
   * @param {*} params.limit
   * @param {*} params.offset
   * @param {*} params.search
   * @param {*} params.local Add LocalData to workOrders
   */
  async getAll(params) {
    return backofficeService.Api().get('workOrder', { params })
      .then(response => {
        return response.data
      })
      .catch((error) => {
        throw error
      })
  },

  /**
   * Get specific workOrder
   * @param {*} id
   * @param {*} params
   * @param {*} params.local Add LocalData to workOrder
   */
  getById(id, params = {}) {
    return backofficeService.Api().get(`workOrder/${id}`, {
        params
      })
      .then(response => response.data)
      .catch((error) => {
        throw error
      })
  },

  /**
   * Create a workOrder. Will also initiate location data
   * @param {*} data
   */
  create(data) {
    return backofficeService.Api().post('workOrder', data)
      .then(response => {
        return response.data
      })
      .catch((error) => {
        throw error
      })
  },

  /**
   * Update specific workOrder
   * @param {*} id
   * @param {*} data
   */
  update(id, data) {
    return backofficeService.Api().put(`workOrder/${id}`, data)
      .then(response => {
        return response.data
      })
      .catch((error) => {
        throw error
      })
  },

  /**
   * Delete workOrder
   * @param {} id
   * @param {*} params
   */
  delete(id, params) {
    return backofficeService.Api().delete(`workOrder/${id}`, {
        params
      })
      .then(response => {
        return response.data
      })
      .catch((error) => {
        throw error
      })
  },

}