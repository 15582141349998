<template>
  <v-container fluid grid-list-lg>
    <Create
      :createDialog="createDialog"
      @close="createDialog = false"
      @submit="getList"
    ></Create>
    <Edit
      :editDialog="editDialog"
      @close="editDialog = false"
      @submit="getList"
      :editWorkOrder="selectedWorkOrder"
    ></Edit>
    <v-card :loading="loading" outlined>
      <ListTitle
        :showBackButton="true"
        title="Work Order"
        @add="add"
      />
      <v-card-title>
        <v-row>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="search"
              outlined
              prepend-inner-icon="search"
              rounded
              dense
              label="Search"
              single-line
              hide-details
              clearable
            ></v-text-field
          ></v-col>
        </v-row>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="WorkOrders"
        :search="search"
        :loading="loading"
        :sort-by="sortBy"
        :page.sync="page"
        :items-per-page="itemsPerPage"
      >
        <template v-slot:item="{ item }">
          <tr>
            <td class="text-left">
              {{ item.createdAt | moment("from", "now") }}
            </td>
            <td class="text-left">{{ item.name }}</td>
            <td class="text-left">
              <span v-if="item.Product">{{ item.Product.name }}</span>
            </td>
            <td class="text-left">
              <span v-if="item.SerialVault">{{ item.SerialVault.imeiNumber }}</span>
            </td>
            <td class="text-left">
              <span v-if="item.Technician">{{ item.Technician.name }}</span>
              <div v-if="item.TechLocation" class="caption">
                TechLocation: {{ item.TechLocation.name }}
              </div>
            </td>
            <td class="text-left">{{ item.status }}</td>
            <td class="justify-end align-center text-right">
              <v-menu bottom left offset-y>
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" icon>
                    <v-icon>more_vert</v-icon>
                  </v-btn>
                </template>
                <v-list class="menu-button">
                  <v-list-item
                    @click="editWorkOrder(item)"
                  >
                    <v-list-item-title>Edit</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    @click="deleteWorkOrder(item)"
                  >
                    <v-list-item-title>Delete</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </td>
          </tr>
        </template>
        <v-alert slot="no-results" :value="true" color="error" icon="warning"
          >Your search for "{{ search }}" found no results.</v-alert
        >
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import workOrderService from "../service";
import { mapGetters } from "vuex";

const Create = () => import("./Create");
const Edit = () => import("./Edit");

export default {
  name: "workOrder-list",
  data() {
    return {
      editDialog: false,
      createDialog: false,
      selectedWorkOrder: null,
      headers: [
        {
          text: "Created At",
          align: "left",
          value: "createdAt",
          sortable: true,
        },
        {
          text: "Name",
          align: "left",
          value: "name",
          sortable: true,
        },
        {
          text: "Product",
          align: "left",
          value: "Product.name",
          sortable: true,
        },
        {
          text: "Serial Number",
          align: "left",
          value: "SerialVault.imeiNumber",
          sortable: true,
        },
        {
          text: "Technician",
          value: "Technician.name",
          sortable: true,
        },
        {
          text: "Status",
          value: "status",
          sortable: true,
        },
        {
          text: "",
          value: "action",
          sortable: false,
        },
      ],
      WorkOrders: [],
      search: null,
      loading: true,
      page: 1,
      sortBy: "balance",
      itemsPerPage: 10,
    };
  },
  created() {
    this.getList();
    document.title = "smplsale - Work Order List";
  },
  computed: {
    ...mapGetters("global", ["currentUser", "checkRightStatus"]),
  },
  components: {
    Create,
    Edit
  },
  methods: {
    add() {
      this.createDialog = !this.createDialog;
    },
    getList() {
      this.loading = true;
      this.editDialog = false;
      this.createDialog = false;
      return workOrderService
        .getAll()
        .then((response) => {
          this.loading = false;
          // console.log("WorkOrders", response);
          this.WorkOrders = response.data;
          return response;
        });
    },
    editWorkOrder(workOrder) {
      this.selectedWorkOrder = workOrder;
      this.editDialog = true;
    },
    deleteWorkOrder(workOrder) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "No",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
          workOrderService.delete(workOrder.id).then((response) => {
            if (response.data) {
              this.$swal("Deleted!", "Work Order has been deleted.", "success");
              this.getList();
            }
          });
        }
      });
    },
  },
};
</script>
