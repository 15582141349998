<template>
  <div>
    <v-toolbar dense flat>
      <v-app-bar-nav-icon to="/workOrder/home" small>
        <v-icon>home</v-icon>
      </v-app-bar-nav-icon>

      <v-toolbar-title>Work Order</v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn text color="teal" dark to="/workOrder/list">
        Work Order List
      </v-btn>
      
      <v-btn text color="teal" dark to="/workOrder/techLocation/list">TechLocation</v-btn>
      <v-btn text color="teal" dark to="/workOrder/technician/list">Technician</v-btn>
    </v-toolbar>
    <v-divider></v-divider>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "workOrder",
  data() {
    return {};
  },
  created() {}
};
</script>
